import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { z } from 'zod';

export enum WaitingListOfferResponses {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export const WaitingListOfferResponseEnum = z.nativeEnum(WaitingListOfferResponses);
export type WaitingListOfferResponseEnum = z.infer<typeof WaitingListOfferResponseEnum>;

export const waitingListOfferPropertySchema = z.object({
  address: z.string(),
  size: z.number().nullable().optional(),
  rooms: z.number().nullable().optional(),
  city: z.string().nullable().optional(),
  postalCode: z.string().nullable().optional(),
  rentalPrice: z.number().nullable().optional(),
  companyName: z.string().nullable().optional(),
});

export const waitingListOfferWaitlisteeSchema = z.object({
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
});

export const waitingListOfferSchema = z.object({
  token: z.string(),
  response: WaitingListOfferResponseEnum.nullable().optional(),
  expiresOn: z.string().nullable().optional(),
  property: waitingListOfferPropertySchema,
  user: waitingListOfferWaitlisteeSchema,
});

export type WaitingListOfferResponse = z.infer<typeof waitingListOfferSchema>;
export type WaitingListOfferApiResponse = z.infer<ReturnType<typeof apiResponseSchema<typeof waitingListOfferSchema>>>;
