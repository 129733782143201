import { optionalWithNull } from 'schemas/generic/optional-with-null';
import { z } from 'zod';

export function apiResponseSchema<T extends z.ZodTypeAny>(data: T) {
  return z.object({
    data: optionalWithNull(data),
  });
}

export function apiPaginatedResponseSchema<T extends z.ZodTypeAny>(data: T) {
  return z.object({
    pagination: z.object({
      page: z.number(),
      pageSize: z.number(),
      total: z.number(),
      pageCount: z.number(),
      hasNextPage: z.boolean(),
      hasPreviousPage: z.boolean(),
    }),
    data: z.array(data),
  });
}

export type ApiResponse<T extends z.ZodTypeAny> = z.infer<ReturnType<typeof apiResponseSchema<T>>>;
export type ApiPaginatedResponse<T extends z.ZodTypeAny> = z.infer<ReturnType<typeof apiPaginatedResponseSchema<T>>>;
