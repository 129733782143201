import { useRouter } from 'next/router';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import { useEffect } from 'react';
import { LanguageKey } from 'types/common';
import { getRoute } from 'utils/languages';

const usePageRenderer = (pageName: string) => {
  const router = useRouter();
  const { restrictedPages, defaultPage } = useWhiteLabel();

  const renderPage = !restrictedPages.includes(pageName);

  useEffect(() => {
    if (!renderPage) {
      router.push(getRoute(defaultPage, router.locale as LanguageKey));
    }
  }, [renderPage, pageName, router, defaultPage]);

  return renderPage;
};

export default usePageRenderer;
