import { useTranslation } from 'next-i18next';

export class APIError extends Error {
  status: number;
  error?: string;
  path?: string;
  timestamp?: string;

  constructor(message: string, status: number, error?: string, path?: string, timestamp?: string) {
    super(message);
    this.name = this.constructor.name;
    this.status = status;
    this.error = error;
    this.path = path;
    this.timestamp = timestamp;
  }
}

export const useFetch = () => {
  const { t, i18n } = useTranslation('apiErrors');

  const ourFetch: typeof fetch = async (input, init) => {
    const response = await fetch(input, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...init,
    });

    if (!response.ok) {
      if (response.status === 400) {
        const { error, message, path, status, timestamp } = await response.json();
        throw new APIError(message, error, path, status, timestamp);
      }

      if (response.status === 401) {
        throw new APIError('Unauthorized', 401);
      }

      if (response.status === 403) {
        throw new APIError('Forbidden', 403);
      }

      if (response.status === 429) {
        throw new APIError('Too Many Requests', 429);
      }

      if (response.status === 500) {
        const { error, message, path, status, timestamp } = await response.json();
        throw new APIError(message, error, path, status, timestamp);
      }

      throw new APIError('Unknown error', response.status);
    }

    return response;
  };

  return { fetch: ourFetch };
};
