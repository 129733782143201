import { apiResponseSchema } from '../api-response/apiResponse';
import { z } from 'zod';

export const membershipSchema = z.object({
  id: z.string(),
  label: z.record(z.string()),
  isCprNumberRequired: z.boolean(),
});

export type Membership = z.infer<typeof membershipSchema>;

export const membershipAPIResponseSchema = apiResponseSchema(z.array(membershipSchema));
export type MembershipAPIResponse = z.infer<typeof membershipAPIResponseSchema>;
