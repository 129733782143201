import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { z } from 'zod';

export const waitingListUserCompaniesSchema = z.object({
  id: z.string(),
  name: z.string(),
  price: z.number(),
});

export const waitingListUserCompaniesArraySchema = z.array(waitingListUserCompaniesSchema);

export type WaitingListUserCompanies = z.infer<typeof waitingListUserCompaniesArraySchema>;
export type WaitingListUserCompaniesApiResponse = z.infer<ReturnType<typeof apiResponseSchema<typeof waitingListUserCompaniesArraySchema>>>;
