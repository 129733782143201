import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { z } from 'zod';

export enum SubscriptionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
}

export enum SubscriptionType {
  WAITING_LIST = 'WAITING_LIST',
}

export const SubscriptionStatusEnum = z.nativeEnum(SubscriptionStatus);
export type SubscriptionStatusEnum = z.infer<typeof SubscriptionStatusEnum>;

export const SubscriptionTypeEnum = z.nativeEnum(SubscriptionType);
export type SubscriptionTypeEnum = z.infer<typeof SubscriptionTypeEnum>;

export const subscriptionSchema = z.object({
  id: z.string(),
  userId: z.string(),
  amount: z.number(),
  startDate: z.string(),
  expiresAt: z.string(),
  addedAt: z.string(),
  updatedAt: z.string(),
  status: SubscriptionStatusEnum,
  type: SubscriptionTypeEnum,
  metaData: z.record(z.any()), // Map<String, Any> as Record<string, any>
});

export const subscriptionSignupSchema = z.object({
  subscription: subscriptionSchema,
  redirectUrl: z.string(),
});

export type Subscription = z.infer<typeof subscriptionSchema>;
export type SubscriptionSignupDto = z.infer<typeof subscriptionSignupSchema>;
export type SubscriptionApiResponse = z.infer<ReturnType<typeof apiResponseSchema<typeof subscriptionSchema>>>;
