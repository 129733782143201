import usePageRenderer from 'hooks/usePageRenderer';
import React from 'react';

interface WhiteLabelPageRendererProps {
  pageName: string;
  children: React.ReactNode;
}

export const WhiteLabelPageRenderer = ({ pageName, children }: WhiteLabelPageRendererProps) => {
  const renderPage = usePageRenderer(pageName);

  if (!renderPage) {
    return null;
  }

  return <>{children}</>;
};
