import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { optionalWithNull } from 'schemas/generic/optional-with-null';
import { z } from 'zod';

export enum BillStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export enum BillPaymentStatus {
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  REJECTED = 'REJECTED',
}

export enum BillReminderStatus {
  NONE = 'NONE',
  SOON_EXPIRING_SENT = 'SOON_EXPIRING_SENT',
  FIRST_SENT = 'FIRST_SENT',
  SECOND_SENT = 'SECOND_SENT',
  THIRD_SENT = 'THIRD_SENT',
  COMPLETED = 'COMPLETED',
}

export const BillStatusEnum = z.nativeEnum(BillStatus);
export type BillStatusEnum = z.infer<typeof BillStatusEnum>;

export const BillPaymentStatusEnum = z.nativeEnum(BillPaymentStatus);
export type BillPaymentStatusEnum = z.infer<typeof BillPaymentStatusEnum>;

export const BillReminderStatusEnum = z.nativeEnum(BillReminderStatus);
export type BillReminderStatusEnum = z.infer<typeof BillReminderStatusEnum>;

export const billSchema = z.object({
  id: z.string(),
  userId: z.string(),
  subscriptionId: z.string(),
  invoiceAmount: z.number(),
  taxAmount: z.number(),
  totalAmount: z.number(),
  description: optionalWithNull(z.string()),
  dueDate: z.string(),
  status: BillStatusEnum,
  addedAt: z.string(),
  updatedAt: z.string(),
  paymentStatus: BillPaymentStatusEnum.nullable(),
  paymentReference: optionalWithNull(z.string()),
  invoiceId: optionalWithNull(z.number()),
  invoiceNumber: optionalWithNull(z.string()),
  orderToken: optionalWithNull(z.string()),
  errorDescription: optionalWithNull(z.string()),
  reminderStatus: BillReminderStatusEnum,
});

export type Bill = z.infer<typeof billSchema>;
export type BillApiResponse = z.infer<ReturnType<typeof apiResponseSchema<typeof billSchema>>>;
