import { FormEvent } from 'react';
import { Button } from 'legacy-ui';
import { Modal, ModalButtons, ModalHeader } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { useTranslation } from 'next-i18next';

interface LeaveWaitingListModalProps {
  isOpen: boolean;
  onClose: () => void;
  leaveWaitingList: () => void;
}

const LeaveWaitingListModal = ({ isOpen, onClose, leaveWaitingList }: LeaveWaitingListModalProps) => {
  const { t } = useTranslation('waitingList');
  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="mb-12">
        <ModalHeader className="font-semibold text-2xl mb-6 md:text-3xl">{t('Are you sure?')}</ModalHeader>
        <Text>{t('Please confirm that you would like to leave this waiting list. You will lose your accrued seniority.')}</Text>
      </div>
      <form
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
          leaveWaitingList();
        }}
      >
        <ModalButtons>
          <div>
            <Button type="submit" color="danger" fullWidth>
              {t('Leave waiting list')}
            </Button>
          </div>
          <div>
            <Button type="button" fullWidth onClick={onClose}>
              {t('Cancel')}
            </Button>
          </div>
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default LeaveWaitingListModal;
